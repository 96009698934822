
































































































































































































import Vue, { PropType } from 'vue';
import axios from 'axios';
import { Instrument, InstrumentTypeInfo, ProtocolInfo, Group } from './types';

import { ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import TapiConfigPage from './TapiConfigPage.vue';
import Adam6017ConfigPage from './Adam6017ConfigPage.vue';
import MqttConfigPage from './MqttConfigPage.vue';
import Mqtt2ConfigPage from './Mqtt2ConfigPage.vue';
import Adam6066ConfigPage from './Adam6066ConfigPage.vue';
import ThetaConfigPage from './ThetaConfigPage.vue';

const emptyForm: Instrument = {
  _id: '',
  instType: '',
  protocol: {
    protocol: '',
    host: undefined,
    comPort: undefined,
  },
  param: '',
  active: true,
  state: '010',
};

export default Vue.extend({
  components: {
    ValidationObserver,
    TapiConfigPage,
    Adam6017ConfigPage,
    MqttConfigPage,
    Mqtt2ConfigPage,
    Adam6066ConfigPage,
    ThetaConfigPage,
  },
  props: {
    isNew: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    inst: {
      type: Object as PropType<Instrument>,
      default: () => emptyForm,
    },
  },
  data() {
    let form: Instrument = emptyForm;
    let isNew = this.isNew;
    if (!isNew) {
      form = Object.assign({}, this.inst);
    }

    const groupList = Array<Group>();
    return {
      form,
      instrumentTypes: Array<string>(),
      instTypeMap: new Map<string, InstrumentTypeInfo>(),
      groupList,
    };
  },
  computed: {
    hasDetailConfig(): boolean {
      if (this.form.instType === 'gps') return false;

      return true;
    },
    isTapiInstrument(): boolean {
      const tapi = ['t100', 't200', 't201', 't300', 't360', 't400', 't700'];
      for (const t of tapi) {
        if (this.form.instType === t) return true;
      }
      return false;
    },
    isAdam6017(): boolean {
      return this.form.instType === 'adam6017';
    },
    isMqtt(): boolean {
      return this.form.instType === 'mqtt_client';
    },
    isMqtt2(): boolean {
      return this.form.instType === 'mqtt_client2';
    },
    isAdam6066(): boolean {
      return this.form.instType === 'adam6066';
    },
    isTheta(): boolean {
      return this.form.instType === 'theta';
    },
    instrumentSummary(): string {
      const formNewline = (input: string) => {
        const newline = String.fromCharCode(13, 10);
        return input.replaceAll('\\n', newline);
      };

      let desc = `儀器ID:${this.form._id}\n`;
      desc += `儀器種類:${this.form.instType}\n`;
      desc += `通訊協定:${this.form.protocol.protocol}\n`;
      if (this.form.protocol.protocol === 'tcp')
        desc += `網址:${this.form.protocol.host}\n`;
      else desc += `COM:${this.form.protocol.comPort}\n`;

      if (this.isTapiInstrument) return (desc += this.tapiSummary());

      return formNewline(desc);
    },
  },
  async mounted() {
    this.getInstrumentTypes();
    await this.getGroupList();
  },
  methods: {
    tapiSummary(): string {
      let desc = '';
      const param = JSON.parse(this.form.param);
      desc += 'slave ID:' + param.slaveID + '\n';
      if (this.form.instType !== 't700') {
        desc += '校正時間:' + param.calibrationTime + '\n';
        desc += '校正上升時間:' + param.raiseTime + '\n';
        desc += '校正持續時間:' + param.holdTime + '\n';
        desc += '校正下降時間:' + param.downTime + '\n';
        if (param.calibrateZeoSeq)
          desc += '零點校正執行程序:' + param.calibrateZeoSeq + '\n';
        if (param.calibrateSpanSeq)
          desc += '全幅校正執行程序:' + param.calibrateSpanSeq + '\n';
        if (param.calibratorPurgeTime)
          desc += '校正器清空時間:' + param.calibratorPurgeTime + '\n';
        if (param.calibratorPurgeSeq)
          desc += '校正器清空執行程序:' + param.calibratorPurgeSeq + '\n';
        if (param.calibrateZeoDO)
          desc += '零點校正DO:' + param.calibrateZeoDO + '\n';
        if (param.calibrateSpanDO)
          desc += '全幅校正DO:' + param.calibrateSpanDO + '\n';
        if (param.skipInternalVault) desc += '不切換校正電磁閥::不切換';
      }
      return desc;
    },
    async getInstrumentTypes(): Promise<void> {
      const res = await axios.get('/InstrumentTypes');
      this.instrumentTypes = res.data;
      for (const instType of res.data) {
        this.instTypeMap.set(instType.id, instType);
      }
    },
    getProtocolOptions(): Array<ProtocolInfo> {
      if (this.form.instType && this.instTypeMap.get(this.form.instType)) {
        let instrumentTypeInfo = this.instTypeMap.get(
          this.form.instType,
        ) as InstrumentTypeInfo;
        return instrumentTypeInfo.protocolInfo;
      } else return [];
    },
    validateInstType(): Promise<boolean> {
      return new Promise((resolve, reject) => {
        let validateOserver = this.$refs['instTypeRules'] as InstanceType<
          typeof ValidationObserver
        >;
        validateOserver.validate().then(success => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validateProtocol(): Promise<boolean> {
      return new Promise((resolve, reject) => {
        let validateOserver = this.$refs['protocolRules'] as InstanceType<
          typeof ValidationObserver
        >;
        validateOserver.validate().then((success: boolean) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validateDetailConfig(): Promise<boolean> {
      return new Promise((resolve, reject) => {
        let validateOserver = this.$refs['detailConfigRules'] as InstanceType<
          typeof ValidationObserver
        >;
        validateOserver.validate().then((success: boolean) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    onParamChange(v: string): void {
      this.form.param = v;
    },
    async formSubmitted() {
      const res = await axios.post('/Instrument', this.form);
      const ret = res.data;
      if (ret.ok) {
        this.$emit('submit');
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '失敗',
            text: ret.msg,
            icon: 'EditIcon',
            variant: 'danger',
          },
        });
      }
    },
    async getGroupList() {
      const res = await axios.get('/Groups');
      if (res.status === 200) {
        this.groupList = res.data;
      }
    },
  },
});
