























































































































































import Vue from 'vue';
import axios from 'axios';
const Ripple = require('vue-ripple-directive');
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import InstrumentWizard from './InstrumentWizard.vue';
import { Group, Instrument, InstrumentInfo } from './types';

export default Vue.extend({
  components: {
    InstrumentWizard,
  },
  directives: {
    Ripple,
  },
  data() {
    const instList = Array<InstrumentInfo>();
    let groupList = Array<Group>();
    const fields = [
      {
        key: 'selected',
        label: '選擇',
      },
      {
        key: '_id',
        label: '儀器名稱',
        sortable: true,
      },
      {
        key: 'instType',
        label: '儀器種類',
        sortable: true,
      },
      {
        key: 'state',
        label: '狀態',
        sortable: true,
      },
      {
        key: 'protocol',
        label: '連線方式',
        sortable: true,
      },
      {
        key: 'protocolParam',
        label: '通訊參數',
        sortable: true,
      },
      {
        key: 'calibrationTime',
        label: '每日校正時間',
        sortable: true,
      },
      {
        key: 'monitorTypes',
        label: '測項',
        sortable: true,
      },
      {
        key: 'group',
        label: '群組',
        sortable: true,
      },
    ];

    const selected = Array<InstrumentInfo>();
    return {
      fields,
      instList,
      isNew: true,
      selected,
      bit: 17,
      on: true,
      groupList,
    };
  },
  computed: {
    modalTitle(): string {
      return this.isNew ? '新增儀器' : '更新儀器設定';
    },
    selectedInstrument(): Instrument | undefined {
      if (!this.isNew && this.selected.length) return this.selected[0].inst;
      else return undefined;
    },
  },
  mounted() {
    this.getInstList();
  },

  methods: {
    onSubmit() {
      this.$bvModal.hide('instModal');
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      });
      this.getInstList();
    },
    showResult(ok: boolean) {
      if (ok) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '成功',
            icon: 'EditIcon',
            variant: 'success',
          },
        });
        this.getInstList();
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '失敗',
            icon: 'EditIcon',
            variant: 'danger',
          },
        });
      }
    },
    async activateInst() {
      const res = await axios.put(
        `/ActivateInstrument/${this.selected[0]._id}`,
        {},
      );
      this.showResult(res.data.ok);
    },
    async deactivateInst() {
      const res = await axios.put(
        `/DeactivateInstrument/${this.selected[0]._id}`,
        {},
      );
      this.showResult(res.data.ok);
    },
    async toggleMaintenanceMode() {
      const res = await axios.put(
        `/ToggleMaintainInstrument/${this.selected[0]._id}`,
        {},
      );
      this.showResult(res.data.ok);
    },
    async calibrateInstrumentZero() {
      const res = await axios.put(
        `/CalibrateInstrumentZero/${this.selected[0]._id}`,
        {},
      );
      this.showResult(res.data.ok);
    },
    async calibrateInstrumentSpan() {
      const res = await axios.put(
        `/CalibrateInstrumentSpan/${this.selected[0]._id}`,
        {},
      );
      this.showResult(res.data.ok);
    },
    async calibrateInstrument() {
      const res = await axios.put(
        `/CalibrateInstrument/${this.selected[0]._id}`,
        {},
      );
      this.showResult(res.data.ok);
    },
    async resetInstrument() {
      const res = await axios.put(
        `/ResetInstrument/${this.selected[0]._id}`,
        {},
      );
      this.showResult(res.data.ok);
    },
    showWriteDoDlg() {
      this.$bvModal.show('writeDoModal');
    },
    async writeDO() {
      const res = await axios.put(`/WriteDO/${this.selected[0]._id}`, {
        bit: this.bit,
        on: this.on,
      });
      this.showResult(res.data.ok);
    },
    newInst() {
      this.isNew = true;
      this.$bvModal.show('instModal');
    },
    updateInst() {
      this.isNew = false;
      this.$bvModal.show('instModal');
    },
    deleteInst() {
      this.$bvModal
        .msgBoxConfirm(`是否要刪除儀器?${this.selected[0]._id}`, {
          okTitle: '是',
          cancelTitle: '否',
          centered: true,
        })
        .then(ret => {
          if (ret) {
            this.delInst(this.selected[0]._id);
          }
        })
        .catch(err => {
          throw Error(err);
        });
    },
    getInstList() {
      axios
        .get('/InstrumentInfos')
        .then(res => {
          this.instList = res.data;
        })
        .catch(err => {
          throw new Error(err);
        });
    },
    onInstSelected(items: Array<InstrumentInfo>) {
      this.selected = items;
    },
    delInst(id: string) {
      axios
        .delete(`/Instrument/${id}`)
        .then(res => {
          const ret = res.data;
          if (ret.ok) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: '成功',
                icon: 'UserIcon',
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: '刪除失敗',
                icon: 'UserIcon',
              },
            });
          }
          this.getInstList();
        })
        .catch(err => {
          throw new Error(err);
        });
    },
    onUpdate() {
      this.$bvModal.hide('instModal');
      this.getInstList();
    },
    onRefresh() {
      this.getInstList();
    },
    onDeleted() {
      this.getInstList();
    },
    async getGroupList() {
      const res = await axios.get('/Groups');
      if (res.status === 200) {
        this.groupList = res.data;
      }
    },
  },
});
