

















import Vue, { PropType } from 'vue';
import { MqttConfig2 } from './types';
import axios from 'axios';

export default Vue.extend({
  props: {
    paramStr: {
      type: String as PropType<string>,
      default: ``,
    },
  },
  data() {
    let paramObj: MqttConfig2 = {
      topic: '',
    };

    if (this.paramStr) paramObj = JSON.parse(this.paramStr);

    return {
      paramObj,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    onChange() {
      this.$emit('param-changed', JSON.stringify(this.paramObj));
    },
  },
});
