var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{staticClass:"wizard-vertical vertical-steps steps-transparent",attrs:{"color":"#7367F0","title":null,"subtitle":null,"layout":"vertical","finish-button-text":"確認","back-button-text":"向前","next-button-text":"向後"},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"title":"儀器種類","before-change":_vm.validateInstType}},[_c('validation-observer',{ref:"instTypeRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"儀器種類","label-for":"instrumentType","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"儀器種類","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"instrumentType","label":"desp","reduce":function (inst) { return inst.id; },"options":_vm.instrumentTypes},model:{value:(_vm.form.instType),callback:function ($$v) {_vm.$set(_vm.form, "instType", $$v)},expression:"form.instType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"儀器ID","label-for":"inst-id","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"儀器ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"inst-id"},model:{value:(_vm.form._id),callback:function ($$v) {_vm.$set(_vm.form, "_id", $$v)},expression:"form._id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"群組","label-for":"inst-id","label-cols-md":"3"}},[_c('v-select',{attrs:{"id":"instrumentGroup","label":"name","reduce":function (group) { return group._id; },"options":_vm.groupList},model:{value:(_vm.form.group),callback:function ($$v) {_vm.$set(_vm.form, "group", $$v)},expression:"form.group"}})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"通訊協定","before-change":_vm.validateProtocol}},[_c('validation-observer',{ref:"protocolRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"通訊協定","label-for":"protocol","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"通訊協定","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"desp","reduce":function (p) { return p.id; },"options":_vm.getProtocolOptions()},model:{value:(_vm.form.protocol.protocol),callback:function ($$v) {_vm.$set(_vm.form.protocol, "protocol", $$v)},expression:"form.protocol.protocol"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.form.protocol.protocol === 'tcp')?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"網址","label-for":"host","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"網址","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"host","placeholder":"localhost"},model:{value:(_vm.form.protocol.host),callback:function ($$v) {_vm.$set(_vm.form.protocol, "host", $$v)},expression:"form.protocol.host"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4120706672)})],1)],1):_vm._e(),(_vm.form.protocol.protocol === 'serial')?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"COM Port","label-for":"com-port","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"通訊埠","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-spinbutton',{attrs:{"min":"1","max":"100"},model:{value:(_vm.form.protocol.comPort),callback:function ($$v) {_vm.$set(_vm.form.protocol, "comPort", $$v)},expression:"form.protocol.comPort"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,983858353)})],1)],1):_vm._e()],1)],1)],1),(_vm.hasDetailConfig)?_c('tab-content',{attrs:{"title":"細部設定","before-change":_vm.validateDetailConfig}},[_c('validation-observer',{ref:"detailConfigRules"},[(_vm.isTapiInstrument)?_c('tapi-config-page',{attrs:{"inst-type":_vm.form.instType,"param-str":_vm.form.param},on:{"param-changed":_vm.onParamChange}}):(_vm.isAdam6017)?_c('adam-6017-config-page',{attrs:{"param-str":_vm.form.param},on:{"param-changed":_vm.onParamChange}}):(_vm.isMqtt)?_c('mqtt-config-page',{attrs:{"param-str":_vm.form.param},on:{"param-changed":_vm.onParamChange}}):(_vm.isMqtt2)?_c('mqtt2-config-page',{attrs:{"param-str":_vm.form.param},on:{"param-changed":_vm.onParamChange}}):(_vm.isAdam6066)?_c('adam-6066-config-page',{attrs:{"param-str":_vm.form.param},on:{"param-changed":_vm.onParamChange}}):(_vm.isTheta)?_c('theta-config-page',{attrs:{"param-str":_vm.form.param},on:{"param-changed":_vm.onParamChange}}):_c('div',[_vm._v("TBD "+_vm._s(_vm.form.instType))])],1)],1):_vm._e(),_c('tab-content',{attrs:{"title":"設定摘要"}},[_c('h3',[_vm._v("儀器設定摘要")]),_c('p',[_c('b-form-textarea',{attrs:{"rows":"10","readonly":"","value":_vm.instrumentSummary}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }